<div class="row h-100 bg-white" *ngIf="survey">
  <div class="col-lg-3 col-xl-2 d-none d-lg-block h-100 pl-0 pr-0">
    <rehub-bar-selector [isSearch]="false" [items]="items" (itemSelected)="changeFormDesktop($event)">
      <ng-template let-item="item">
        <div class="row align-items-start border-bottom border-top bg-white pt-3 pb-3 mr-0 ml-0 pointer form"
          [ngClass]="{selected: item.form.selected, pointer: !item.form.selected}">
          <div class="d-flex flex-row align-items-center pl-2" style="gap: 10px">
            <rehub-icon name="shape_circle" size="10" style="color:#EB5757;" class="d-flex" *ngIf="!formsComplets[item.form.name]"></rehub-icon>
            <h4 class="mb-0">{{item.form.name}}</h4>
            <!-- <span [title]="item.form.description">{{(item.form.abbreviation | truncateText: 25)}}</span> -->
          </div>
        </div>
      </ng-template>
    </rehub-bar-selector>
  </div>

  <div class="col-lg-9 col-xl-10 col-sm-12 h-100 pr-0 pl-0 d-flex flex-column overflow-hidden">
    <div class="container-fluid d-flex flex-column overflow-auto flex-fill pr-0 pl-0">
      <div class="w-100 mx-auto flex-fill flex-column d-flex pr-0 pl-0">
        <div class="section-div flex-grow-1 flex-column d-flex">
          <div
            class="w-100 mx-auto flex-shrink-1 pr-2 pl-2 d-block d-sm-block d-md-block d-lg-none d-xl-none rehub-background border-bottom">
            <select class="custom-select custom-select-lg mt-2 mb-2" [ngModel]="selectedForm"
              (ngModelChange)='changeFormMobile($event)'>
              <ng-container *ngFor="let item of items">
                <option value="{{item.form.name}}">{{item.form.description}}</option>
              </ng-container>
            </select>
          </div>
          <div class="row flex-fill ml-0 mr-0">
            <div class="col overflow-hidden flex-shrink-1 position-relative" *ngIf="isComplete">
              <div class="position-absolute h-100">
                <div class="row d-flex justify-content-center h-100">
                  <img src="/assets/images/formComplete.svg" />
                  <h3 class="col-10 col-sm-10 col-md-10 col-lg-8 col-xl-10 ml-auto mr-auto mt-2" translate
                    [translateParams]="{form: formId}">form_received</h3>
                </div>
              </div>
            </div>
            <div class="col overflow-auto flex-shrink-1 position-relative pl-0 pr-0" *ngIf="!isComplete" id="formContainer">
              <div class="position-absolute h-100 w-100">
                <div class="w-100 mx-auto flex-shrink-1 p-3 border-bottom">
                  <p class="small mb-0" style="line-height: 22px;">{{survey.description}}</p>
                </div>
                <ng-container *ngFor="let section of survey.sections">
                  <div class="pl-3 pr-3 pt-3 pb-3">
                    <h3>{{section.title}}</h3>
                    <p class="small">{{section.description}}</p>
                    <ng-container *ngFor="let question of section.questions">
                      <div class="row rowForm">

                        <div class="col-12 col-sm-12 col-md-12 pt-3 pb-1 pb-lg-3 pl-4 pr-2 align-self-center" [ngClass]="{'col-xl-6 col-lg-6': formId != 'MRC' && formId != 'RDQ',
                          'col-xl-10 col-lg-10': formId == 'MRC' || formId == 'RDQ'}">
                          <h5>{{question.text}}</h5>
                        </div>

                        <div class="col-12 col-sm-12 col-md-12 align-self-center pt-1 pt-lg-3 pb-3 formQuest" [ngClass]="{'col-xl-6 col-lg-6': formId != 'MRC' && formId != 'RDQ',
                          'col-xl-2 col-lg-2': formId == 'MRC' || formId == 'RDQ'}">

                          <!-- TODO! if B-SAQ DASS-21-->
                          <rehub-form-balls *ngIf="formService.getAnswerOfType(question.answerType).type == 'balls'"
                            [numBalls]="formService.getAnswerOfType(question.answerType).answers.length"
                            [selectedNumber]="answers[question.id]?.i"
                            (numberChange)="setAnswer(question, $event, section)"
                            [showNumbers]="formService.getAnswerOfType(question.answerType).answers.length > 1"
                            [startNumber]="formId == 'B-SAQ' || formId == 'DASS-21'  ? 0 : 1">
                          </rehub-form-balls>

                          <rehub-form-slider *ngIf="formService.getAnswerOfType(question.answerType).type == 'slider'"
                            [min]="formService.getAnswerOfType(question.answerType).min"
                            [max]="formService.getAnswerOfType(question.answerType).max"
                            [number]="answers[question.id] && answers[question.id].v ? answers[question.id].v : 0"
                            (changeSlider)="setAnswer(question, $event, section)"></rehub-form-slider>

                          <rehub-form-checkbox *ngIf="formService.getAnswerOfType(question.answerType).type == 'text'"
                            [items]="formService.getAnswerOfType(question.answerType).answers"
                            [answered]="answers[question.id]?.v"
                            [id]="question.id"
                            (changeCheckbox)="setAnswer(question, $event, section)">
                          </rehub-form-checkbox>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </ng-container>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="w-100 mx-auto flex-shrink-1 pt-2 pb-0 pb-lg-2 border-top bg-white">
        <div
          class="row flex-shrink-1 mr-0 ml-0 bg-white align-items-center borderAction pt-1 pt-lg-3 pb-1 pb-lg-3 pl-2 pr-2"
          *ngIf="!isComplete">
          <div class="col-12 col-lg-5 col-xl-6 pl-4 pr-4 pl-xl-5 pr-xl-5 mb-3 mb-lg-0 mb-xl-0">
            <div class="row mb-1" *ngIf="formId != 'RDQ'">
              <div class="col text-primary font-weight-bold">{{percentageAnswered}}%</div>
              <div class="col text-right">{{answered}}/{{totalAnswers}}</div>
            </div>
            <div class="progress" style="height: 4px;" *ngIf="formId != 'RDQ'">
              <div class="progress-bar bg-primary" role="progressbar" [ngStyle]="{width: percentageAnswered + '%'}"
                aria-valuemin="0" aria-valuemax="100"></div>
            </div>
          </div>
          <div class="col-6 col-lg-4 col-xl-3 pl-1 pr-1 mb-2 mb-lg-0 mb-xl-0">
            <button type="button" class="btn btn-secondary btn mx-auto w-100 pl-1 pr-1" (click)="reset()">
              <span class="font-weight-bold">{{'functional_assessment_step_dash_score_reset' | translate}}</span>
            </button>
          </div>
          <div class="col-6 col-lg-3 pl-1 pr-2 mb-2 mb-lg-0 mb-xl-0">
            <button type="button" class="btn btn-primary btn mx-auto w-100 pl-1 pr-1" (click)="save()">
              <span class="font-weight-bold" *ngIf="!saving">{{'general_send' | translate}}</span>
              <rehub-spinner size="1" *ngIf="saving" class="mx-auto"></rehub-spinner>
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>