import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { SharedI18nService } from '@rehub-shared/service/i18n.service';
import { TranslateService } from "@ngx-translate/core";
import { API } from "@rehub-shared/api";
import { CoachService } from '@app/@core/service/coach.service';
import { environment } from '@env/environment';
/**
 * Pass-through function to mark a string for translation extraction.
 * Running `npm translations:extract` will include the given string by using this.
 * @param s The string to extract for translation.
 * @return The same string.
 */
 export function extract(s: string) {
  return s;
}

@Injectable({
  providedIn: 'root',
})
export class I18nService extends SharedI18nService {

  constructor(
    private translateService: TranslateService
  ){
    super();
  }

  private languages = {
    "spanish": "es",
    "italian": "it",
    "catalan": "ca",
    "english": "en",
    "french": "fr",
    "german": "de",
    "portuguese": "pt",
    "dutch": "nl",
    "spanish-mexico": "esMX"
  }

  setTranslation(language: string, translations: any): void {
    super.setTranslation(language, translations);
    this.translateService.setTranslation(language, translations);
  }

  setLanguage(language: string){
    return this.loadTranslations(language).then(()=>{
      super.setLanguage(language);
      moment.locale(this.getLanguageCode());
      this.translateService.use(language);
    }).catch(()=>{
      console.log("Error loading language config")
    });

  }

  loadTranslations(lang?: string){
    let language:string = lang ? lang : this.getDefaultLanguage();

    let batch = {
      translations: this.getSingleTranslation(language),
      coachMessagesLength: this.getSingleLangCoachMessagesLength(language)
    };

    return API.allFromObject(batch).then((result: any)=>{
      if(result.translations){
          Object.keys(this.languages).forEach((key: string)=>{
            let langCode = this.languages[key];
            let translation = { ...result.translations[`general_${langCode}`], ...result.translations[`protocols_${langCode}`], ...result.translations["form"] };
            this.setTranslation(key, translation);
          })
          this.setMessagesLength(result.coachMessagesLength);
      }
    });
  }

  getDefaultLanguage(){
    let lang = navigator.language;
    let saved = localStorage.getItem('language');
    let foundLanguage = environment.supportedLanguages.find((l: any)=> lang.includes(l.code) || lang.includes(l.flag));
    let defaultLanguage = foundLanguage ? foundLanguage.name : environment.defaultLanguage;
    return saved || defaultLanguage;
  }

  getSingleTranslation(language: string){
    const version = environment.media_version;
    let files = ["general", "protocols"];
    let lang = this.languages[language];
    let batch: any = {};

    files.forEach((file: string) => {
      let key = file + "_" + lang;
      batch[key] = API.getWithoutAuth(`${environment.web_resources_url}/editor-assets/${version}/i18n/${key}.json`);
    });

    let formLangCodes = {
      "es": "es",
      "ca": "es",
      "esMX": "es",
      "en": "en",
      "it": "it",
      "fr": "en",
      "de": "en",
      "pt": "en",
      "nl": "en"
    }
    let fomKey = "form_" + formLangCodes[lang];
    batch["form"] = API.getWithoutAuth(`${environment.web_resources_url}/editor-assets/${version}/i18n/${fomKey}.json`);
    return API.allFromObject(batch);
  }

  getSingleLangCoachMessagesLength(language: string){
    const version = environment.media_version;
    let batch: any = {};
    let lang = this.languages[language];
    batch[lang] = API.getWithoutAuth(`${environment.web_resources_url}/editor-assets/${version}/coach/coach_messages_length_${lang}.json`);

    return API.allFromObject(batch);
  }

  translate(key: string, args?: any): string {
    return this.translateService.instant(key, args);
  }

}
