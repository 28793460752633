<div class="modal-header">
    <h5 class="modal-title" >{{ "modal_material_needed_title" | translate }}</h5>
    <a class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
      <rehub-icon name="close" size="15"></rehub-icon>
    </a>
</div>

<div class="modal-body feet-body d-flex flex-column">
    <p class="desc-1 mb-2">{{ "modal_material_desc_1" | translate }}</p>
    <p class="desc-2">{{ "modal_material_desc_2" | translate }}</p>

    <ng-container *ngFor="let material of data; let i = index">
      <div class="accordion d-flex justify-content-between" (click)="togglePanel(material, i)">
        <div class="d-flex">
          <div class="material-icon">
            <rehub-icon
              [attr.name]="SharedUtils.getToolIcon(material)"
              size="20">
            </rehub-icon>
          </div>
          <div class="material-title flex align-content-center pl-2"><span>{{ SharedUtils.getToolIcon(material) | translate}}</span></div>
        </div>
        <div class="d-flex align-items-center">
          <div class="flex">
            <rehub-icon name="arrow" class="ml-2 icon-toggle" size="15"></rehub-icon>
          </div>
        </div>
      </div>
      <div class="panel" [ngClass]="{'open': isPanelOpen(material)}">
        <video controls loop class="w-100" #videoElement>
          <source [src]="getMaterialInstructionsVideoURL(material)" type="video/mp4"/>
        </video>
      </div>
    </ng-container>

</div>

<div class="modal-footer border-0 pt-0 d-flex justify-content-center">
    <button type="button" class="btn btn-primary btn-lg d-flex flex-row align-items-center justify-content-center"
      (click)="startNextExercise()">
      <span>{{"welcome_tutorial_start_button" | translate}}</span>
    </button>
</div>
