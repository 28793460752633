import { SharedEventBusService } from "./eventbus.service";

type onboardingSlug  = {
    slug: string;
    audio: string;
};

export class SharedOnboardingService{

    constructor(
        private eventBus: SharedEventBusService
    ){}

    audioElement: HTMLAudioElement;
    audioAssetsUrl: string;
    languageCode: string;
    patientId: string;
    isScoachHidden: boolean;

    getOnboardingSlugs(): onboardingSlug[] {
        return [
            {slug: "introTour", audio: "aa118f8e-5495-11ef-bbec-2a5b95c666bc"},
            {slug: "chromeTour", audio: "8a424d40-54a1-11ef-af27-660c6da97904"},
            {slug: "nextExerciseTour", audio: "48b42bba-5496-11ef-a454-eab3c6c1293b"},
            {slug: "completedExercisesTour", audio: "08a04b72-54ae-11ef-9cf7-b630cb43051f"},
            {slug: "timeRemainingTour", audio: "3fa94e28-555f-11ef-b3fb-ca0a15e65524"},
            {slug: "calendarTour", audio: "e4a5d612-555f-11ef-b3fb-ca0a15e65524"},
            {slug: "moreExercisesTour", audio: "8f883624-5560-11ef-b3fb-ca0a15e65524"},
            {slug: "calendarioTour", audio: "25e6ca22-5561-11ef-b3fb-ca0a15e65524"},
            {slug: "chatTour", audio: "86141198-5561-11ef-b3fb-ca0a15e65524"},
            {slug: "progressTour", audio: "e4b436c4-5561-11ef-b3fb-ca0a15e65524"},
            {slug: "virtualCoachTour", audio: "7385b99a-5562-11ef-b3fb-ca0a15e65524"},
            {slug: "profileTour", audio: "6cf586d6-5563-11ef-b3fb-ca0a15e65524"},
            {slug: "finalTour", audio: "0d357778-5564-11ef-b3fb-ca0a15e65524"}
        ]
    };

    protected initOnboardingShared(languageCode: string, patientId: string, isScoachHidden: boolean){
        let node = document.createElement('script');
        node.innerHTML = `window.usetifulTags = { language : "${languageCode}" }`;
        node.type = 'text/javascript';
        document.getElementsByTagName('body')[0].appendChild(node);
        this.audioElement = document.createElement("audio");
        this.languageCode = languageCode;
        this.patientId = patientId;
        this.audioAssetsUrl = `https://web-resources.dycare-services.com/editor-assets/1.75.0/coach/COACH_AUDIO`;
        this.isScoachHidden = isScoachHidden;
    };

    protected generateOnboardingListenersShared(){
        let tours = localStorage.getItem('tours');
        const parsedTours = JSON.parse(tours as string);
        if(parsedTours[0] && parsedTours[0].state === 'closed'){
            this.sendStatus("closed");
            return;
        }
        this.eventBus.publish("COACH_DISABLE");

        window.addEventListener('click', (e => {
            let elementClass = (e.target as HTMLElement).className;
            if (elementClass === 'uf-button' || (e.target as HTMLElement)?.closest("[data-uf-button='close']")) {
                this.closeOnboardingListenersShared();
            }
        }));
        this.getOnboardingSlugs().forEach((item:onboardingSlug)=>{
            window.addEventListener(item.slug, (e => {
                this.stopAudio();
                if((<any>e).detail){
                    let slug = (<any>e).detail.name;
                    this.eventBus.publish("ONBOARDING", {"step":slug});
                    this.playAudio(item.audio);
                    if(slug === "finalTour"){
                        this.closeOnboardingListenersShared();
                    }
                }
            }
        ));
    })};

    protected sendStatus(status: string){
        this.eventBus.publish("ONBOARDING", {"step":status});
    }

    protected closeOnboardingListenersShared(){
        this.sendStatus("closed")
        if(!this.isScoachHidden){
            this.eventBus.publish("COACH_ENABLE");
        }
        this.getOnboardingSlugs().forEach((item:onboardingSlug)=>{
            window.removeEventListener( item.slug, (e => {} ));
        });
    };

    protected onbordingThemeShared(primary: string, secondary: string){
        let node = document.createElement('style');
        node.innerHTML = `
            .uf-button{
                background-color: white !important;
                border-color: ${primary} !important;
                padding: 10px 20px !important;
                border-radius: 40px !important;
            }
            .uf-button-primary {
                background-color: ${primary} !important;
            }
            .uf-button-primary:hover {
                background-color: ${secondary} !important;
                border-color: ${secondary} !important;
                color: white !important;
            }
            .uf-actions {
                 background: linear-gradient(180deg, #ffffff 0%, ${primary}80 100%);
            }
        `;
        node.type = 'text/css';
        document.getElementsByTagName('head')[0].appendChild(node);
    };

    private getAudioURL(key: string){
        return `${this.audioAssetsUrl}/${this.languageCode}/${key}.mp3`;
    };

    private playAudio(audio: string){
        let url = this.getAudioURL(audio);
        this.audioElement.setAttribute("src", url);
        this.audioElement.play();
        this.audioElement.onended = () => {};
    };

    private stopAudio(): void{
        if(!this.audioElement) return;
        this.audioElement.pause();
        this.audioElement.currentTime = 0;
    };

}