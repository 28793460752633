<div class="modal-header">
  <h3 class="modal-title">{{"patient_modal_finished_daily_title" | translate}}</h3>

  <a class="close" data-dismiss="modal" aria-label="Close" (click)="onClose()">
    <rehub-icon name="close" size="15"></rehub-icon>
  </a>
</div>

<div class="modal-body d-flex flex-column" style="background: #BAF8F166;">
  <img class="w-100 h-100 mx-auto" [src]="url" style="max-width: 500px;" />
  <p class="text-center text-primary mb-4">{{"patient_modal_finished_daily_body" | translate}}
  </p>
</div>

<div class="modal-footer border-0 pt-0 d-flex justify-content-center" style="background: #BAF8F166;">
  <div>
    <button type="button" class="btn btn-primary btn-lg d-flex flex-row align-items-center justify-content-center px-4"
      style="border-radius: 30px;"  (click)="visitProgress()">
      <span>Visitar</span>
    </button>
    <div class="text-center text-primary mb-4 mt-4">
      <a data-dismiss="modal" aria-label="Close" (click)="onClose()">
        {{"general_close" | translate}}
      </a>
  </div>

</div>