
type SpeechRecognitionError = "none" | "unsupported" | "permissions" | "language" | "at-stop";

export class SpeechRecognitionService {
  recognition: any;
  isListening: boolean = false;
  message: string = '';
  language: string = 'es-ES'; // Default language
  error: SpeechRecognitionError = "none";

  constructor() {
    try {
      const SpeechRecognition = (window as any).SpeechRecognition || (window as any).webkitSpeechRecognition;
      if (!SpeechRecognition) {
        this.error = "unsupported";
        // TODO: Popup with error message
        throw new Error('SpeechRecognition API not supported in this browser.');
      }
      this.recognition = new SpeechRecognition();
      this.recognition.lang = this.language;
      this.recognition.interimResults = true;
      this.recognition.continuous = true;

      const languageCode = this.getLanguageCode();
      this.setLanguage(languageCode);

      // Reset listening state when recognition ends
      this.recognition.onend = () => {
        this.isListening = false;
      };

      // Handle recognition errors
      this.recognition.onerror = (event: any) => {
        console.error('Speech recognition error:', event.error);
        this.isListening = false;
      };

    } catch (error) {
      this.error = "unsupported";
      console.error('Error initializing SpeechRecognitionService:', error);
    }
  }

  getLanguageCode() {
    try {
      const userLanguage = localStorage.getItem('language');

      switch (userLanguage) {
        case "spanish":
          return 'es-ES';
        case "english":
          return 'en-GB';
        case "italian":
          return 'it-IT';
        case "catalan":
          return 'ca-ES';
        case "french":
          return 'fr-FR';
        case "german":
          return 'de-DE';
        case "portuguese":
          return 'pt-PT';
        case "dutch":
          return 'nl-NL';
        case "spanish-mexico":
          return 'es-MX';
        default:
          return 'es-ES';
      }
    } catch (error) {
      console.error('Error getting language code:', error);
      return 'es-ES';
    }
  }

  startListening() {
    if (!this.isListening) {
      navigator.mediaDevices.getUserMedia({ audio: true }).then((stream) => {
        this.isListening = true;
        this.recognition.start();
      }).catch((err) => {
        this.error = "permissions";
        // TODO: Popup with error message
        console.error('Error obtaining microphone permissions:', err);
      });
    }
  }

  stopListening() {
    if (this.isListening) {
      try {
        this.recognition.stop();
        this.isListening = false;
      } catch (error) {
        this.error = "at-stop";
        // TODO: Popup with error message
        console.error('Error stopping recognition:', error);
      }
    }
  }

  toggleListening() {
    if (!this.isListening) {
      this.startListening();
    } else {
      this.stopListening();
    }
  }

  getMessage() {
    return this.message;
  }

  setLanguage(language: string) {
    try {
      this.language = language;
      this.recognition.lang = language;
    } catch (error) {
      this.error = "language";
      // TODO: Popup with error message. Unsupported?
      console.error('Error setting language:', error);
    }
  }
}